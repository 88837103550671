import addDays from 'date-fns/addDays';
import addMinutes from 'date-fns/addMinutes';
import addMonths from 'date-fns/addMonths';
import addSeconds from 'date-fns/addSeconds';
import addWeeks from 'date-fns/addWeeks';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import differenceInYears from 'date-fns/differenceInYears';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import isWithinInterval from 'date-fns/isWithinInterval';
import parse from 'date-fns/parse';
import startOfDay from 'date-fns/startOfDay';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

import {
  utcDate as utcDateInternal,
  utcDateOrNow as utcDateOrNowInternal,
  utcDateOrNull as utcDateOrNullInternal,
  utcDateOrUndefined as utcDateOrUndefinedInternal
} from '../_internal/utcDate';

export const utcDate = utcDateInternal;

export const utcDateOrNow = utcDateOrNowInternal;

export const utcDateOrUndefined = utcDateOrUndefinedInternal;

export const utcDateOrNull = utcDateOrNullInternal;

export const dateOrNow = (value: Date | string | number | undefined) => {
  try {
    return new Date(value);
  } catch {
    return new Date();
  }
};

export const startOfToday = () => {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

export const utcFormatDate = (value: Date, noYears?: boolean) => {
  try {
    if (!value) {
      return '';
    }

    let formatString = 'd MMM';
    if (!noYears) formatString += ' yyyy';
    return format(value, formatString);
  } catch (err) {
    console.error(err);
    return '';
  }
};

export const utcFormatDateTime = (value: Date, noYears?: boolean) => {
  try {
    if (!value) {
      return '';
    }

    let formatString = 'd MMM';
    if (!noYears) formatString += ' yyyy';
    formatString += ' hh:mm a';
    return format(value, formatString);
  } catch (err) {
    console.error(err);
    return '';
  }
};

/**
 * Returns the relative time between two dates like '3 days ago'.
 * @param left The later date.
 * @param right The earlier date.
 */
export const dateAgo = (left: Date, right: Date): string => {
  const dateLeft = utcDateOrUndefined(left);
  const dateRight = utcDateOrUndefined(right);
  if (!dateLeft || !dateRight) {
    return '';
  }
  const inPast = isBefore(dateLeft, dateRight);
  const prefix = inPast ? '' : 'in ';
  const suffix = inPast ? ' ago' : '';
  const yearsDiff = Math.abs(differenceInYears(dateLeft, dateRight));
  if (yearsDiff >= 1) {
    return compile(period(yearsDiff, 'yr', true));
  }
  const monthsDiff = Math.abs(differenceInMonths(dateLeft, dateRight));
  if (monthsDiff >= 1) {
    return compile(period(monthsDiff, 'mth', true));
  }
  const secondsDiff = Math.abs(differenceInSeconds(dateLeft, dateRight));
  if (secondsDiff >= 60 * 60 * 24) {
    return compile(period(secondsDiff / (60 * 60 * 24), 'day', true));
  }
  if (secondsDiff >= 60 * 60) {
    return compile(period(secondsDiff / (60 * 60), 'hr', true));
  }
  if (secondsDiff >= 60) {
    return compile(period(secondsDiff / 60, 'min', true));
  }
  if (secondsDiff >= 1) {
    return compile(period(secondsDiff, 'sec', true));
  }
  return 'now';

  function compile(text) {
    return `${prefix}${text}${suffix}`;
  }

  function period(value, name, hasPlural) {
    const rounded = Math.floor(value);
    if (rounded <= 0) {
      // should never occur
      return `about 1 ${name}`;
    }
    return rounded === 1 || !hasPlural
      ? `${rounded} ${name}`
      : `${rounded} ${name}s`;
  }
};

/**
 * The relative date from now.
 * @param value The value of the date to compare.
 */
export const dateAgoFromNow = (value: Date): string =>
  dateAgo(value, new Date());

/**
 * Formats a date range for display.
 * @param start The optional start date.
 * @param end The optional end date.
 * @returns The formatted date range string.
 */
export const utcFormatDateTimeRange = (
  start?: Date,
  end?: Date,
  options?: {
    startVariant?: 'DateTime' | 'Date';
    endVariant?: 'DateTime' | 'Date';
    lowercase?: boolean;
    notSetValue?: string;
  }
): string => {
  if (!start && !end) {
    return options?.notSetValue || 'Not set';
  }

  const startFormat =
    options?.startVariant === 'Date' ? utcFormatDate : utcFormatDateTime;
  const endFormat =
    options?.endVariant === 'Date' ? utcFormatDate : utcFormatDateTime;

  if (start && end) {
    const yearSame = start.getFullYear() === end.getFullYear();
    return `${startFormat(start, yearSame)} to ${endFormat(end)}`;
  }
  return start ? `from ${startFormat(start)}` : `until ${endFormat(end)}`;
};

/**
 * Formats a date range for display.
 * @param start The optional start date.
 * @param end The optional end date.
 * @returns The formatted date range string.
 */
export const utcFormatDateTimeRangeShort = (
  start?: Date,
  end?: Date,
  options: {
    lowercase?: boolean;
    notSetValue?: string;
  } = {}
): string => {
  return utcFormatDateTimeRange(start, end, {
    startVariant: 'Date',
    endVariant: 'Date',
    ...options
  });
};

export class DateBuilder {
  private _date: Date;
  constructor(date?: Date) {
    this._date = date || new Date();
  }

  build() {
    return this._date;
  }

  startOfDay() {
    this._date = startOfDay(this._date);
    return this;
  }

  addSeconds(seconds: number) {
    this._date = addSeconds(this._date, seconds);
    return this;
  }
}

/**
 * Function for sorting dates in ascending order from oldest to newest.
 * Undefined/null values are considered to be "oldest" so will come first.
 */
export const sortByDateAscending = (a?: Date, b?: Date) => {
  return (a ? a.getTime() : 0) - (b ? b.getTime() : 0);
};

/**
 * Function for sorting dates in descending order from newest to oldest.
 * Undefined/null values are considered to be "oldest" so will come last.
 */
export const sortByDateDescending = (a?: Date, b?: Date) => {
  return (b ? b.getTime() : 0) - (a ? a.getTime() : 0);
};

/**
 * Function for sorting dates in descending order from newest to oldest.
 * Undefined/null values will come first.
 */
export const sortByDateDescendingUndefinedFirst = (a?: Date, b?: Date) => {
  if (a === undefined && b === undefined) {
    return 0;
  }

  if (a === undefined) {
    return -1;
  }

  if (b === undefined) {
    return 1;
  }

  return (b ? b.getTime() : 0) - (a ? a.getTime() : 0);
};

export const getMinuteSecondDuration = (options: {
  start: Date;
  end: Date;
}) => {
  // Calculate the difference in milliseconds
  const diffMs = differenceInMilliseconds(options.end, options.start);

  // Convert milliseconds into minutes and seconds
  const minutes = Math.floor((diffMs / (1000 * 60)) % 60)
    .toString()
    .padStart(2, '0');

  const seconds = Math.floor((diffMs / 1000) % 60)
    .toString()
    .padStart(2, '0');

  // Format the duration as MM:SS
  return `${minutes}:${seconds}`;
};

/**
 * Return the current date for use in a filename.
 * e.g. will return "2021-11-22" which you can then interpolate into a filename.
 */
export const getDateForFileName = () => {
  return format(new Date(), 'yyyy-MM-dd');
};

//https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
export const doDateRangesIntersect = (a: MinMax<Date>, b: MinMax<Date>) => {
  return a.min <= b.max && a.max >= b.min;
};

type MinMax<T = number> = { min: T; max: T };

export const isWithinXMinutesOf = (
  myDate: Date,
  targetDate: Date,
  minutes: number
) => {
  const comparisonInterval = {
    start: addMinutes(targetDate, -minutes),
    end: addMinutes(targetDate, minutes)
  };

  return isWithinInterval(myDate, comparisonInterval);
};

export const xDaysAgo = (days: number) => addDays(new Date(), -days);
export const oneDayAgo = () => xDaysAgo(1);
export const xWeeksAgo = (weeks: number) => addWeeks(new Date(), -weeks);
export const oneWeekAgo = () => xWeeksAgo(1);
export const xMonthsAgo = (months: number) => addMonths(new Date(), -months);
export const oneMonthAgo = () => xMonthsAgo(1);

//https://stackoverflow.com/questions/2388115/get-locale-short-date-format-using-javascript
const formats = {
  'af-ZA': 'yyyy/MM/dd' as const,
  'am-ET': 'dd/MM/yyyy' as const,
  'ar-AE': 'dd/MM/yyyy' as const,
  'ar-BH': 'dd/MM/yyyy' as const,
  'ar-DZ': 'dd-MM-yyyy' as const,
  'ar-EG': 'dd/MM/yyyy' as const,
  'ar-IQ': 'dd/MM/yyyy' as const,
  'ar-JO': 'dd/MM/yyyy' as const,
  'ar-KW': 'dd/MM/yyyy' as const,
  'ar-LB': 'dd/MM/yyyy' as const,
  'ar-LY': 'dd/MM/yyyy' as const,
  'ar-MA': 'dd-MM-yyyy' as const,
  'ar-OM': 'dd/MM/yyyy' as const,
  'ar-QA': 'dd/MM/yyyy' as const,
  'ar-SA': 'dd/MM/yyyy' as const,
  'ar-SY': 'dd/MM/yyyy' as const,
  'ar-TN': 'dd-MM-yyyy' as const,
  'ar-YE': 'dd/MM/yyyy' as const,
  'arn-CL': 'dd-MM-yyyy' as const,
  'as-IN': 'dd-MM-yyyy' as const,
  'az-Cyrl-AZ': 'dd.MM.yyyy' as const,
  'az-Latn-AZ': 'dd.MM.yyyy' as const,
  'ba-RU': 'dd.MM.yyyy' as const,
  'be-BY': 'dd.MM.yyyy' as const,
  'bg-BG': 'dd.MM.yyyy' as const,
  'bn-BD': 'dd-MM-yyyy' as const,
  'bn-IN': 'dd-MM-yyyy' as const,
  'bo-CN': 'yyyy/MM/dd' as const,
  'br-FR': 'dd/MM/yyyy' as const,
  'bs-Cyrl-BA': 'dd.MM.yyyy' as const,
  'bs-Latn-BA': 'dd.MM.yyyy' as const,
  'ca-ES': 'dd/MM/yyyy' as const,
  'co-FR': 'dd/MM/yyyy' as const,
  'cs-CZ': 'dd.MM.yyyy' as const,
  'cy-GB': 'dd/MM/yyyy' as const,
  'da-DK': 'dd-MM-yyyy' as const,
  'de-AT': 'dd.MM.yyyy' as const,
  'de-CH': 'dd.MM.yyyy' as const,
  'de-DE': 'dd.MM.yyyy' as const,
  'de-LI': 'dd.MM.yyyy' as const,
  'de-LU': 'dd.MM.yyyy' as const,
  'dsb-DE': 'dd.MM.yyyy' as const,
  'dv-MV': 'dd/MM/yyyy' as const,
  'el-GR': 'dd/MM/yyyy' as const,
  'en-029': 'MM/dd/yyyy' as const,
  'en-AU': 'dd/MM/yyyy' as const,
  'en-BZ': 'dd/MM/yyyy' as const,
  'en-CA': 'dd/MM/yyyy' as const,
  'en-GB': 'dd/MM/yyyy' as const,
  'en-IE': 'dd/MM/yyyy' as const,
  'en-IN': 'dd-MM-yyyy' as const,
  'en-JM': 'dd/MM/yyyy' as const,
  'en-MY': 'dd/MM/yyyy' as const,
  'en-NZ': 'dd/MM/yyyy' as const,
  'en-PH': 'MM/dd/yyyy' as const,
  'en-SG': 'dd/MM/yyyy' as const,
  'en-TT': 'dd/MM/yyyy' as const,
  'en-US': 'MM/dd/yyyy' as const,
  'en-ZA': 'yyyy/MM/dd' as const,
  'en-ZW': 'MM/dd/yyyy' as const,
  'es-AR': 'dd/MM/yyyy' as const,
  'es-BO': 'dd/MM/yyyy' as const,
  'es-CL': 'dd-MM-yyyy' as const,
  'es-CO': 'dd/MM/yyyy' as const,
  'es-CR': 'dd/MM/yyyy' as const,
  'es-DO': 'dd/MM/yyyy' as const,
  'es-EC': 'dd/MM/yyyy' as const,
  'es-ES': 'dd/MM/yyyy' as const,
  'es-GT': 'dd/MM/yyyy' as const,
  'es-HN': 'dd/MM/yyyy' as const,
  'es-MX': 'dd/MM/yyyy' as const,
  'es-NI': 'dd/MM/yyyy' as const,
  'es-PA': 'MM/dd/yyyy' as const,
  'es-PE': 'dd/MM/yyyy' as const,
  'es-PR': 'dd/MM/yyyy' as const,
  'es-PY': 'dd/MM/yyyy' as const,
  'es-SV': 'dd/MM/yyyy' as const,
  'es-US': 'MM/dd/yyyy' as const,
  'es-UY': 'dd/MM/yyyy' as const,
  'es-VE': 'dd/MM/yyyy' as const,
  'et-EE': 'dd.MM.yyyy' as const,
  'eu-ES': 'yyyy/MM/dd' as const,
  'fa-IR': 'MM/dd/yyyy' as const,
  'fi-FI': 'dd.MM.yyyy' as const,
  'fil-PH': 'MM/dd/yyyy' as const,
  'fo-FO': 'dd-MM-yyyy' as const,
  'fr-BE': 'dd/MM/yyyy' as const,
  'fr-CA': 'yyyy-MM-dd' as const,
  'fr-CH': 'dd.MM.yyyy' as const,
  'fr-FR': 'dd/MM/yyyy' as const,
  'fr-LU': 'dd/MM/yyyy' as const,
  'fr-MC': 'dd/MM/yyyy' as const,
  'fy-NL': 'dd-MM-yyyy' as const,
  'ga-IE': 'dd/MM/yyyy' as const,
  'gd-GB': 'dd/MM/yyyy' as const,
  'gl-ES': 'dd/MM/yyyy' as const,
  'gsw-FR': 'dd/MM/yyyy' as const,
  'gu-IN': 'dd-MM-yyyy' as const,
  'ha-Latn-NG': 'dd/MM/yyyy' as const,
  'he-IL': 'dd/MM/yyyy' as const,
  'hi-IN': 'dd-MM-yyyy' as const,
  'hr-BA': 'dd.MM.yyyy.' as const,
  'hr-HR': 'dd.MM.yyyy' as const,
  'hsb-DE': 'dd.MM.yyyy' as const,
  'hu-HU': 'yyyy.MM.dd.' as const,
  'hy-AM': 'dd.MM.yyyy' as const,
  'id-ID': 'dd/MM/yyyy' as const,
  'ig-NG': 'dd/MM/yyyy' as const,
  'ii-CN': 'yyyy/MM/dd' as const,
  'is-IS': 'dd.MM.yyyy' as const,
  'it-CH': 'dd.MM.yyyy' as const,
  'it-IT': 'dd/MM/yyyy' as const,
  'iu-Cans-CA': 'dd/MM/yyyy' as const,
  'iu-Latn-CA': 'dd/MM/yyyy' as const,
  'ja-JP': 'yyyy/MM/dd' as const,
  'ka-GE': 'dd.MM.yyyy' as const,
  'kk-KZ': 'dd.MM.yyyy' as const,
  'kl-GL': 'dd-MM-yyyy' as const,
  'km-KH': 'yyyy-MM-dd' as const,
  'kn-IN': 'dd-MM-yyyy' as const,
  'ko-KR': 'yyyy.MM.dd' as const,
  'kok-IN': 'dd-MM-yyyy' as const,
  'ky-KG': 'dd.MM.yyyy' as const,
  'lb-LU': 'dd/MM/yyyy' as const,
  'lo-LA': 'dd/MM/yyyy' as const,
  'lt-LT': 'yyyy.MM.dd' as const,
  'lv-LV': 'yyyy.MM.dd.' as const,
  'mi-NZ': 'dd/MM/yyyy' as const,
  'mk-MK': 'dd.MM.yyyy' as const,
  'ml-IN': 'dd-MM-yyyy' as const,
  'mn-MN': 'yyyy.MM.dd' as const,
  'mn-Mong-CN': 'yyyy/MM/dd' as const,
  'moh-CA': 'MM/dd/yyyy' as const,
  'mr-IN': 'dd-MM-yyyy' as const,
  'ms-BN': 'dd/MM/yyyy' as const,
  'ms-MY': 'dd/MM/yyyy' as const,
  'mt-MT': 'dd/MM/yyyy' as const,
  'nb-NO': 'dd.MM.yyyy' as const,
  'ne-NP': 'MM/dd/yyyy' as const,
  'nl-BE': 'dd/MM/yyyy' as const,
  'nl-NL': 'dd-MM-yyyy' as const,
  'nn-NO': 'dd.MM.yyyy' as const,
  'nso-ZA': 'yyyy/MM/dd' as const,
  'oc-FR': 'dd/MM/yyyy' as const,
  'or-IN': 'dd-MM-yyyy' as const,
  'pa-IN': 'dd-MM-yyyy' as const,
  'pl-PL': 'dd.MM.yyyy' as const,
  'prs-AF': 'dd/MM/yyyy' as const,
  'ps-AF': 'dd/MM/yyyy' as const,
  'pt-BR': 'dd/MM/yyyy' as const,
  'pt-PT': 'dd-MM-yyyy' as const,
  'qut-GT': 'dd/MM/yyyy' as const,
  'quz-BO': 'dd/MM/yyyy' as const,
  'quz-EC': 'dd/MM/yyyy' as const,
  'quz-PE': 'dd/MM/yyyy' as const,
  'rm-CH': 'dd/MM/yyyy' as const,
  'ro-RO': 'dd.MM.yyyy' as const,
  'ru-RU': 'dd.MM.yyyy' as const,
  'rw-RW': 'MM/dd/yyyy' as const,
  'sa-IN': 'dd-MM-yyyy' as const,
  'sah-RU': 'MM.dd.yyyy' as const,
  'se-FI': 'dd.MM.yyyy' as const,
  'se-NO': 'dd.MM.yyyy' as const,
  'se-SE': 'yyyy-MM-dd' as const,
  'si-LK': 'yyyy-MM-dd' as const,
  'sk-SK': 'dd. MM. yyyy' as const,
  'sl-SI': 'dd.MM.yyyy' as const,
  'sma-NO': 'dd.MM.yyyy' as const,
  'sma-SE': 'yyyy-MM-dd' as const,
  'smj-NO': 'dd.MM.yyyy' as const,
  'smj-SE': 'yyyy-MM-dd' as const,
  'smn-FI': 'dd.MM.yyyy' as const,
  'sms-FI': 'dd.MM.yyyy' as const,
  'sq-AL': 'yyyy-MM-dd' as const,
  'sr-Cyrl-BA': 'dd.MM.yyyy' as const,
  'sr-Cyrl-CS': 'dd.MM.yyyy' as const,
  'sr-Cyrl-ME': 'dd.MM.yyyy' as const,
  'sr-Cyrl-RS': 'dd.MM.yyyy' as const,
  'sr-Latn-BA': 'dd.MM.yyyy' as const,
  'sr-Latn-CS': 'dd.MM.yyyy' as const,
  'sr-Latn-ME': 'dd.MM.yyyy' as const,
  'sr-Latn-RS': 'dd.MM.yyyy' as const,
  'sv-FI': 'dd.MM.yyyy' as const,
  'sv-SE': 'yyyy-MM-dd' as const,
  'sw-KE': 'MM/dd/yyyy' as const,
  'syr-SY': 'dd/MM/yyyy' as const,
  'ta-IN': 'dd-MM-yyyy' as const,
  'te-IN': 'dd-MM-yyyy' as const,
  'tg-Cyrl-TJ': 'dd.MM.yyyy' as const,
  'th-TH': 'dd/MM/yyyy' as const,
  'tk-TM': 'dd.MM.yyyy' as const,
  'tn-ZA': 'yyyy/MM/dd' as const,
  'tr-TR': 'dd.MM.yyyy' as const,
  'tt-RU': 'dd.MM.yyyy' as const,
  'tzm-Latn-DZ': 'dd-MM-yyyy' as const,
  'ug-CN': 'yyyy-MM-dd' as const,
  'uk-UA': 'dd.MM.yyyy' as const,
  'ur-PK': 'dd/MM/yyyy' as const,
  'uz-Cyrl-UZ': 'dd.MM.yyyy' as const,
  'uz-Latn-UZ': 'dd/MM yyyy' as const,
  'vi-VN': 'dd/MM/yyyy' as const,
  'wo-SN': 'dd/MM/yyyy' as const,
  'xh-ZA': 'yyyy/MM/dd' as const,
  'yo-NG': 'dd/MM/yyyy' as const,
  'zh-CN': 'yyyy/MM/dd' as const,
  'zh-HK': 'dd/MM/yyyy' as const,
  'zh-MO': 'dd/MM/yyyy' as const,
  'zh-SG': 'dd/MM/yyyy' as const,
  'zh-TW': 'yyyy/MM/dd' as const,
  'zu-ZA': 'yyyy/MM/dd' as const
};

export const getCurrentLocale = () => {
  const dateFormat = new Intl.DateTimeFormat('default').resolvedOptions();
  // default is returning locale as en-US in Aus for unknown reasons
  // needs a proper investigation but using timezone as a workaround in interim
  if (
    dateFormat.locale === 'en-US' &&
    dateFormat.timeZone.startsWith('Australia')
  ) {
    return 'en-AU';
  }

  return dateFormat.locale;
};

export const getLocaleDateString = (locale?: string): string => {
  if (locale) {
    const format = formats[locale] as string;
    if (!!format) return format;
  }

  return (formats[getCurrentLocale()] as string) || 'dd/MM/yyyy';
};

export const parseStringDate = (dateString: string) => {
  const localeDate = getLocaleDateString();
  const date = parse(dateString, localeDate, new Date());
  if (!isValid(date)) return undefined;
  return zonedTimeToUtc(date, 'UTC');
};

export const tryGetZonedDate = (
  utc: Date,
  timeZoneId: string
): Date | undefined => {
  if (!!utc && !!timeZoneId) {
    return utcToZonedTime(utc, timeZoneId);
  }

  return undefined;
};

/**
 * Given the UTC date and the time zone id, return the formatted date time.
 */
export const formatZonedDateTime = (
  utc: Date,
  timeZoneId?: string,
  noYears?: boolean
) => {
  return utcFormatDateTime(tryGetZonedDate(utc, timeZoneId), noYears);
};
